import {createContext, ReactNode, useState} from "react";
import { TarefaType } from "../entity/TarefaEntity";

type TarefaContextType = {
  tarefas: TarefaType[],
  setTarefas: Function
}

const TarefasContext = createContext<TarefaContextType>(null!);

type TarefaProviderType = {
  children: ReactNode
}

const TarefasProvider = ({children}: TarefaProviderType) => {
  const [tarefas, setTarefas] = useState([]);
  const value = {tarefas, setTarefas};
  
  return (
    <TarefasContext.Provider value={value}>
      {children}
    </TarefasContext.Provider>
  );
}

export {TarefasContext, TarefasProvider};
import {Button, Dialog, Divider, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {InsigniaAPI} from "../ModalInsignia/api";
import {useSnackbar} from "notistack";
import {UserContext} from "../../../../contexts/UserContext";
import {FaTimes} from "react-icons/fa";

export type InsigniaType = {
  id?: number,
  uuid: string,
  nome: string,
  link: string,
  descricao?: string,
  titulo?: string,
  informacao?: string,
  load?(): void,
  UserUID: string,
  canDelete?: boolean
}

const Insignia = (
  {
    uuid,
    load,
    UserUID,
    nome,
    link,
    descricao,
    titulo,
    informacao,
    canDelete,
    id
  }: InsigniaType) => {
  
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(false);
  const [formTitulo, setTitulo] = useState('');
  const {enqueueSnackbar} = useSnackbar();
  const {user} = useContext(UserContext);
  
  const toggleOpen = () => {
    setOpen(!open);
  }
  
  useEffect(() => {
    if (!open) {
      setForm(false);
    }
  }, [open])
  
  const send = async () => {
    try {
      let retorno = await InsigniaAPI.set(user.token, uuid, UserUID, formTitulo);
      if (!retorno.error) {
        enqueueSnackbar("Sucesso!", {variant: "success"});
        if (load) {
          load();
        }
        setOpen(false);
      } else {
        enqueueSnackbar(retorno.error.description, {variant: "error"});
      }
    } catch (e) {
      enqueueSnackbar(`${e}`, {variant: "error"});
    }
  }
  
  const deleteInsignia = async () => {
    try {
      let retorno;
      if (typeof id === 'number') {
        retorno = await InsigniaAPI.delete(user.token, id);
        if (!retorno.error) {
          enqueueSnackbar("Sucesso!", {variant: "success"});
          if (load) {
            load();
          }
        } else {
          enqueueSnackbar(retorno.error.description, {variant: "error"});
        }
      }
    } catch (e) {
      enqueueSnackbar(`${e}`, {variant: "error"});
    }
  }
  
  return (
    <div>
      <div className={"insignia"}>
        <Tooltip onClick={toggleOpen} title={titulo ? titulo : nome}>
          <img src={`${process.env.REACT_APP_API_URL}${link}`} alt={nome}/>
        </Tooltip>
        {(canDelete && id) &&
          <div className={"btn-delete-insignia"}>
            <Tooltip title={"Revogar Insígnia"}>
              <IconButton onClick={deleteInsignia}>
                <FaTimes color={"red"}/>
              </IconButton>
            </Tooltip>
          </div>
        }
      </div>
      <Dialog onClose={toggleOpen} open={open} PaperProps={{sx: {maxWidth: "80%", width: "100%", padding: "10px"}}}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} sx={{textAlign: "center"}}>
            <img src={`${process.env.REACT_APP_API_URL}${link}`} alt={nome} style={{width: "90%"}}/>
          </Grid>
          <Grid item xs={12} sm={12} md={8} sx={{borderLeft: "1px solid #ffffff1e"}}>
            <h4>{titulo ? titulo : nome}</h4>
            {descricao &&
              <h6>{descricao}</h6>
            }
            {informacao ?
              <>
                <Divider/>
                <p style={{fontSize: "14px", fontWeight: "200"}}>{informacao}</p>
              </>
              :
              (
                <div style={{display: "flex", flexDirection: "column"}}>
                  {form ?
                    <>
                      <TextField
                        label={"Substituir título? (Opcional)"}
                        variant={"outlined"}
                        value={formTitulo}
                        onChange={(t) => {
                          setTitulo(t.target.value)
                        }}
                        size={"small"}
                        margin={"normal"}
                      />
                      <Button size={"small"} variant={"contained"} onClick={send}>
                        Conceder
                      </Button>
                    </>
                    :
                    <Button size={"small"} variant={"contained"} onClick={() => {
                      setForm(true)
                    }}>
                      Conceder
                    </Button>
                  }
                </div>
              )
            }
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default Insignia;
import {Button} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useSnackbar} from "notistack";
import LogoHW from "../../../assets/img/logo-h-w.png";
import LogoHB from "../../../assets/img/logo-h-b.png";
import {ApplicationContext} from "../../../contexts/ApplicationContext";
import {UserContext} from "../../../contexts/UserContext";
import CampoDeTexto from "../../inputs/components/CampoDeTexto";
import CampoDeTextoSenha from "../../inputs/components/CampoDeTextoSenha";
import useLogin from "../functions/useLogin";
import useDecode from "../functions/useDecode";
import useSWR from "swr";

const Login = () => {
  const [_username, setUsername] = useState('');
  const [_password, setPassword] = useState('');
  const [token, setToken] = useState('');
  
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {appInfo} = useContext(ApplicationContext);
  const {setUser} = useContext(UserContext);
  const login = useLogin();
  const decode = useDecode();
  
  const decodeFetch = useSWR(token !== '' ? token : null, (token: string) => decode(token).then(res => res.data));
  
  useEffect(() => {
    if (!decodeFetch.isValidating && (decodeFetch.data || decodeFetch.error)) {
      if (decodeFetch.data) {
        setUser(
          {
            token: token,
            uuid: decodeFetch.data.decode.uuid,
            nome: decodeFetch.data.decode.nome,
            avatar: decodeFetch.data.decode.foto,
            nivel: decodeFetch.data.decode.nivel
          }
        );
        
        localStorage.setItem('token', token);
        
        enqueueSnackbar("Login efetuado", {variant: "success"});
        navigate('/', {replace: true});
      } else {
        enqueueSnackbar(`${decodeFetch.error}`, {variant: "error"});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodeFetch]);
  
  
  const send = () => {
    login(_username, _password)
      .then((respostaLogin) => {
        setToken(respostaLogin.data.token);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.error.description, {variant: "error"});
      })
    ;
  }
  
  return (
    <div className={"login"}>
      <div className={"login-panel"}>
        <div className={"logo"}>
          <a href={"https://itweb.net.br"} target={"_blank"} rel="noreferrer">
            <img src={appInfo.theme === 'dark' ? LogoHW : LogoHB} alt={"Logo"}/>
          </a>
        </div>
        <h5 style={{marginBottom: 0}}>Bem vindo(a) de volta!</h5>
        <p style={{marginBottom: "20px", fontWeight: 200}}>Vamos construir algo grande</p>
        
        <CampoDeTexto
          label={"Usuário"}
          value={_username}
          onChange={(t) => {
            setUsername(t.target.value)
          }}
        />
        
        <CampoDeTextoSenha
          label={"Senha"}
          value={_password}
          change={(t) => {
            setPassword(t.target.value);
          }}
          enterFunction={send}
        />
        <Button
          size={"large"}
          variant={"contained"}
          sx={{mt: "10px"}}
          onClick={send}
        >
          Entrar
        </Button>
      </div>
    </div>
  );
}

export default Login;
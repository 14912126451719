import {createContext, ReactNode, useState} from "react";

type appInfoType = {
  theme: string,
  menuOpen: boolean,
  cabecalho: ReactNode
}

type AppContextType = {
  appInfo: appInfoType,
  setAppInfo: Function
}
const ApplicationContext = createContext<AppContextType>(null!);

type AppProviderType = {
  children: ReactNode
}

const ApplicationProvider = ({children}: AppProviderType) => {
  const [appInfo, setAppInfo] = useState(
    {
      theme: 'dark',
      menuOpen: false,
      cabecalho: <></>
    }
  );

  const value = {appInfo, setAppInfo};

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
}

export {ApplicationContext, ApplicationProvider};
import {TextField} from "@mui/material";
import {ChangeEventHandler} from "react";
type Argumentos = {label: string, value: string, onChange: ChangeEventHandler<HTMLInputElement>, disabled?: boolean}
const CampoDeTexto = ({label, value, onChange, disabled}:Argumentos) => {
  return (
    <TextField
      label={label}
      variant={"outlined"}
      margin={"normal"}
      fullWidth
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default CampoDeTexto;
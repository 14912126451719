import {Outlet} from "react-router";
import {ApplicationProvider} from "./contexts/ApplicationContext";
import {SnackbarProvider} from 'notistack';
import {UserProvider} from "./contexts/UserContext";
import HttpsRedirect from "./domains/base/components/HttpsRedirect";
import {TarefasProvider} from "./domains/tarefas/contexts/TarefasContext";
import {ReloadProvider} from "./contexts/ReloadContext";

function App() {
  
  return (
    <SnackbarProvider maxSnack={3}>
      <ApplicationProvider>
        <UserProvider>
          <TarefasProvider>
            <ReloadProvider>
              <HttpsRedirect>
                <Outlet/>
              </HttpsRedirect>
            </ReloadProvider>
          </TarefasProvider>
        </UserProvider>
      </ApplicationProvider>
    </SnackbarProvider>
  );
}

export default App;

import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {FaEyeSlash, FaRegEye} from "react-icons/fa";
import {ChangeEventHandler, useState} from "react";

type Argumentos = {
  label: string,
  value: string,
  change: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  enterFunction?: Function
}

const CampoDeTextoSenha = ({label, value, change, enterFunction}: Argumentos) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }
  
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin={"normal"}
    >
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={change}
        onKeyDown={enterFunction ? (event) => {
          if (event.key === 'Enter') {
            enterFunction()
          }
        } : () => {}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
            >
              {showPassword ? <FaEyeSlash/> : <FaRegEye/>}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}

export default CampoDeTextoSenha;
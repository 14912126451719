import {Backdrop, CircularProgress} from "@mui/material";
import LogoHB from "../../../../assets/img/logo-h-b.png";

const FakeLoad = () => {
  return (
    <Backdrop
      sx={{flexDirection: "column", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open
    >
      <img style={{width: "200px", marginBottom: "20px"}} src={LogoHB}
           alt={"Itweb Publicidade"}/>
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
}

export default FakeLoad;
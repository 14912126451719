import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './main.min.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./domains/layout/components/Layout";
import Inicio from "./domains/inicio/screens";
import Login from "./domains/autenticacao/screens";
import FakeLoad from "./domains/base/components/FakeLoad";
import Load from "./domains/base/components/Load";
import NotFound from "./domains/base/screens/notfound";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Contato = lazy(() => (import('./domains/contato/screens')));
const Sobre = lazy(() => (import('./domains/sobre/screens')));
const Politica = lazy(() => (import('./domains/politica/screens')));

const Administrativo = lazy(() => (import('./domains/administrativo/layout/layout')));

const Cliente = lazy(() => (import('./domains/cliente/screens')));
const ClienteCreate = lazy(() => (import('./domains/cliente/screens/create')));
const ClienteRead = lazy(() => (import('./domains/cliente/screens/read')));
const ClienteUpdate = lazy(() => (import('./domains/cliente/screens/update')));

const Planejamento = lazy(() => (import('./domains/planejamento/screens')));
const PlanejamentoRead = lazy(() => (import('./domains/planejamento/screens/read')));
const PlanejamentoPdf = lazy(() => (import('./domains/planejamento/screens/pdf')));
const PostCreate = lazy(() => (import('./domains/planejamento/screens/create')));
const PostUpdate = lazy(() => (import('./domains/planejamento/screens/update')));

const Usuario = lazy(() => (import('./domains/usuario/screens')));
const UsuarioCreate = lazy(() => (import('./domains/usuario/screens/create')));
const UsuarioRead = lazy(() => (import('./domains/usuario/screens/read')));
const UsuarioPerfil = lazy(() => (import('./domains/usuario/screens/perfil')));

const Tarefa = lazy(() => (import('./domains/tarefas/screens')));
const TarefaCreate = lazy(() => (import('./domains/tarefas/screens/create')));
const TarefaUpdate = lazy(() => (import('./domains/tarefas/screens/update')));

const Agendamento = lazy(() => (import('./domains/agendamento/screens/agendamento')));

root.render(
  <BrowserRouter>
    <Suspense fallback={<FakeLoad/>}>
      <Routes>
        <Route element={<App/>}>
          <Route element={<Load/>}>
            <Route path={"/"} element={<Layout/>}>
              <Route index element={<Inicio/>}/>
              
              <Route path={"login"} element={<Login/>}/>
              <Route path={"sobre"} element={<Sobre/>}/>
              <Route path={"contato"} element={<Contato/>}/>
              <Route path={"politica"} element={<Politica/>}/>
              <Route path={"perfil"} element={<UsuarioPerfil/>}/>
              <Route path={"perfil/:uuid"} element={<UsuarioPerfil/>}/>
              
              <Route path={"administrativo"}>
                <Route index element={<Administrativo/>}/>
              </Route>
              
              <Route path={"agendamento"}>
                <Route index element={<Agendamento/>}/>
              </Route>
              
              <Route path={"cliente"}>
                <Route index element={<Cliente/>}/>
                <Route path={"create"} element={<ClienteCreate/>}/>
                <Route path={"read/:uuid"} element={<ClienteRead/>}/>
                <Route path={"update/:uuid"} element={<ClienteUpdate/>}/>
                <Route path={"delete/:uuid"}/>
              </Route>
              
              <Route path={"planejamento"}>
                <Route index element={<Planejamento/>}/>
                <Route path={"postcreate/:cliente_uuid/:mes/:ano"} element={<PostCreate/>}/>
                <Route path={"postupdate/:uuid"} element={<PostUpdate/>}/>
                <Route path={"read/:cliente_uuid/:mes/:ano"} element={<PlanejamentoRead/>}/>
                <Route path={"read/:cliente_uuid/:mes/:ano/pdf"} element={<PlanejamentoPdf/>}/>
              </Route>
              
              <Route path={"usuario"}>
                <Route index element={<Usuario/>}/>
                <Route path={"create"} element={<UsuarioCreate/>}/>
                <Route path={"update/:uuid"} element={<UsuarioCreate/>}/>
                <Route path={"read"} element={<UsuarioRead/>}/>
                <Route path={"delete/:uuid"}/>
              </Route>
              
              <Route path={"tarefa"}>
                <Route index element={<Tarefa/>}/>
                <Route path={"create"} element={<TarefaCreate/>}/>
                <Route path={"update/:uuid"} element={<TarefaUpdate/>}/>
              </Route>
            
            </Route>
          </Route>
        </Route>
        <Route path={"*"} element={<NotFound/>}/>
      </Routes>
    </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
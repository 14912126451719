import {createContext, ReactNode, useState} from "react";
type UserType = {
  uuid: string,
  nome: string,
  token: string,
  avatar: string,
  nivel: string
}

type UserContextType = {
  user: UserType,
  setUser: Function
}
const UserContext = createContext<UserContextType>(null!);

type UserProviderType = {
  children: ReactNode
}

const UserProvider = ({children}: UserProviderType) => {
  const [user, setUser] = useState<UserType>({uuid:'',nome:'',avatar:'',token:'',nivel:''});

  const value:UserContextType = {user, setUser};

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export {UserContext, UserProvider};
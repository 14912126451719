export const InsigniaAPI = {
  get: async (token: string) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/insignia`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-Token': token
      }
    });

    return await response.json();
  },
  
  getInsignias: async (token: string, uuid: string) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/insignia/getinsignias`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Token': token
      },
      body: JSON.stringify({uuid})
    });

    return await response.json();
  },

  create: async (token: string, nome: string, descricao: string, arquivo?: any, uuid?: string) => {
    let form = new FormData();
    form.append('nome', nome);
    form.append('descricao', descricao);
    if (arquivo) {
      form.append('arquivo', arquivo);
    }
    if (uuid) {
      form.append('uuid', uuid);
    }
    
    let response = await fetch(`${process.env.REACT_APP_API_URL}/insignia/create`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-Token': token
      },
      body: form
    });

    return await response.json();
  },
  
  set: async (token: string, insignia_uuid: string, usuario_uuid: string, titulo?: string) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/insignia/set`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Token': token
      },
      body: JSON.stringify({
        usuario_uuid,
        insignia_uuid,
        titulo
      })
    });

    return await response.json();
  },
  
  delete: async (token: string, id: number) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/insignia/delete`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Token': token
      },
      body: JSON.stringify({id})
    });

    return await response.json();
  }
}
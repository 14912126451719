import useAxiosBase from "../../../../connections/functions/useAxiosBase";
import useSWR from "swr";

const useCarregarComunicados = (token: string)  => {
  const requisicao = useAxiosBase(token);
  const fetcherGET = (url: string) => requisicao.get(url).then((res) => res.data);
  
  let key;
  if (token && token !== '') {
    key = `${process.env.REACT_APP_API_URL}/comunicado`;
  } else {
    key = null;
  }
  
  return useSWR(key, fetcherGET);
}

export default useCarregarComunicados;
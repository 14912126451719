import {IconType} from "react-icons";
import {FaHome, FaRegClipboard, FaStore, FaTrello, FaUsers, FaUsersCog} from "react-icons/fa";
import {BsCalendar3} from "react-icons/bs";

type ItemMenuType = {
  acesso: Array<string>,
  titulo: string,
  rota: string,
  icone: IconType
}

const ITENS_MENU: Array<ItemMenuType> = [
  {
    acesso: [],
    titulo: "Início",
    rota: "/",
    icone: FaHome,
  },
  {
    acesso: ['admin'],
    titulo: "Administrativo",
    rota: "/administrativo",
    icone: FaUsersCog,
  },
  {
    acesso: ['admin','planner'],
    titulo: "Agendamento",
    rota: "/agendamento",
    icone: BsCalendar3,
  },
  {
    acesso: ['admin','atendimento','planner'],
    titulo: "Clientes",
    rota: "/cliente",
    icone: FaStore,
  },
  {
    acesso: ['admin','atendimento','planner','designer','fotografia'],
    titulo: "Planejamentos",
    rota: "/planejamento",
    icone: FaRegClipboard,
  },
  {
    acesso: [],
    titulo: "Tarefas",
    rota: "/tarefa",
    icone: FaTrello,
  },
  {
    acesso: ['admin'],
    titulo: "Usuários",
    rota: "/usuario",
    icone: FaUsers,
  },
];

export default ITENS_MENU;
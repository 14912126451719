import {createContext, ReactNode, useState} from "react";

type ReloadContextType = {
  reloadState: boolean,
  reload: Function,
}
const ReloadContext = createContext<ReloadContextType>(null!);

type ReloadProviderType = {
  children: ReactNode
}

const ReloadProvider = ({children}: ReloadProviderType) => {
  const [reloadState, setReload] = useState(true);

  const reload = () => {
    setReload(!reloadState);
  }
  
  const value = {reloadState, reload};

  return (
    <ReloadContext.Provider value={value}>
      {children}
    </ReloadContext.Provider>
  );
}

export {ReloadContext, ReloadProvider};
import axios from "axios";

const useAxiosBase = (token: string) => {
  if (token !== '') {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'X-Token': token
      }
    });
  }
  else {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json'
      }
    });
  }
}

export default useAxiosBase;
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../../../contexts/ApplicationContext";
import {UserContext} from "../../../../contexts/UserContext";
import {Outlet, useNavigate} from "react-router";
import {Backdrop, CircularProgress} from "@mui/material";
import LogoHW from "../../../../assets/img/logo-h-w.png";
import LogoHB from "../../../../assets/img/logo-h-b.png";
import useDecode from "../../../autenticacao/functions/useDecode";
import {useEffectOnce} from "usehooks-ts";
import useSWR from "swr";

const Load = () => {
  const [carregado, setCarregado] = useState(false);
  const {appInfo} = useContext(ApplicationContext);
  const {user, setUser} = useContext(UserContext);
  const navigate = useNavigate();
  const decode = useDecode();
  const [token, setToken] = useState<string | null>(null);
  
  const decodeFetch = useSWR(token ,(token: string) => decode(token).then(res => res.data));
  
  useEffect(() => {
    if (!decodeFetch.isValidating && (decodeFetch.data || decodeFetch.error)) {
      if (decodeFetch.data) {
        setUser(
          {
            token: token,
            uuid: decodeFetch.data.decode.uuid,
            nome: decodeFetch.data.decode.nome,
            avatar: decodeFetch.data.decode.foto,
            nivel: decodeFetch.data.decode.nivel
          }
        );
      } else {
        navigate("/login")
      }
      setToken(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodeFetch]);
  
  const load = () => {
    let tokenLocal = localStorage.getItem('token');
    
    if (user.token === '' && tokenLocal && tokenLocal !== '') {
      setToken(tokenLocal);
    } else {
      navigate('/login');
    }
    setCarregado(true);
  }
  
  useEffectOnce(load);
  
  if (!carregado) {
    load();
  }
  
  return (
    <>
      {carregado ?
        <Outlet/>
        :
        <Backdrop
          sx={{flexDirection: "column", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <img style={{width: "200px", marginBottom: "20px"}} src={appInfo.theme === 'dark' ? LogoHW : LogoHB} alt={"Itweb Publicidade"}/>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </>
  );
}

export default Load;
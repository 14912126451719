import Lottie from 'react-lottie';
import Animacao from '../../../assets/animations/404-error.json'
import {Link} from "react-router-dom";

const NotFound = () => {
  return (
    <div style={{width:"100%",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",backgroundColor:"#1e2128"}}>
      <div style={{maxWidth:"70%",width:"100%"}}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: Animacao,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />
      </div>
      <h4>Não foi possível encontrar a página que você está procurando</h4>
      <Link to={"/"} style={{color:"#a5b703"}}>
        Voltar para início
      </Link>
    </div>
  );
}

export default NotFound;
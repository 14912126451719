import {Avatar, Button, CircularProgress, Divider, Grid, Icon, Paper} from "@mui/material";
import {useContext, useState} from "react";
import {UserContext} from "../../../contexts/UserContext";
import {useNavigate} from "react-router";
import ITENS_MENU from "../misc/menu";
import {useEffectOnce} from "usehooks-ts";
import useCarregarInsignias from "../../usuario/functions/useCarregarInsignias";
import Insignia, {InsigniaType} from "../../insignia/components/Insignia";
import useCarregarComunicados from "../../administrativo/comunicados/functions/useCarregarComunicados";
import {Comunicado} from "../../administrativo/comunicados/screens/comunicados";
import formatDateString from "../../base/functions/formatDateString";
import {BsPinAngleFill} from "react-icons/bs";
import parse from 'html-react-parser';
import {ApplicationContext} from "../../../contexts/ApplicationContext";

const Inicio = () => {
  //States
  const [insignias, setInsignias] = useState<Array<InsigniaType>>([]);
  
  //Hooks
  const {user} = useContext(UserContext);
  const {appInfo, setAppInfo} = useContext(ApplicationContext);
  const navigate = useNavigate();
  const carregarInsignias = useCarregarInsignias();
  
  const comunicados = useCarregarComunicados(user.token);
  
  useEffectOnce(() => {
    setAppInfo({
      ...appInfo,
      cabecalho:
        <h1 style={{marginBottom: 0, marginTop: 0, fontSize: '30px', marginLeft: '10px', fontWeight: 600}}>
          Início
        </h1>
    });
    carregarInsignias(user.token, user.uuid, setInsignias);
  });
  
  const loadMenuItens: () => (Array<any>) = () => {
    if (ITENS_MENU.length > 0) {
      let array: Array<any> = [];
      for (let i = 0; i < ITENS_MENU.length; i++) {
        if (ITENS_MENU[i].acesso.length > 0) {
          let temAcesso = false;
          ITENS_MENU[i].acesso.forEach((nivel) => {
            if (user.nivel.includes(nivel)) {
              temAcesso = true;
            }
          });
          if (temAcesso) {
            array.push(ITENS_MENU[i]);
          }
        } else {
          array.push(ITENS_MENU[i]);
        }
      }
      return array;
    } else {
      return [];
    }
  }
  
  return (
    <div className={"pagina"}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={6} lg={8}>
          <div className={"painel-geral"}>
            <Grid container columns={13} spacing={1}>
              {loadMenuItens().map((item, index) =>
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} className={"acesso-rapido"}
                      onClick={() => {
                        navigate(item.rota)
                      }}>
                  <Icon component={item.icone}/>
                  <span>{item.titulo}</span>
                </Grid>
              )}
            </Grid>
          </div>
          {!comunicados.data && comunicados.isValidating ?
            <CircularProgress/>
            :
            (comunicados.data && comunicados.data.data.length > 0) &&
            <div className={"painel-geral"}>
              <h6>Comunicados</h6>
              {comunicados.data.data.map((comunicado: Comunicado, index: number) => (
                <Paper
                  style={comunicado.urgente ? {backgroundColor: "#cc0000"} : {}}
                  sx={{marginTop: "15px"}}
                  elevation={3}
                  key={`${comunicado.uuid}-${index}`}
                >
                  <Paper
                    style={comunicado.urgente ? {backgroundColor: "#990000"} : {}}
                    sx={{padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between"}}
                  >
                    <span style={{width: "100px"}}>{formatDateString(comunicado.data)}</span>
                    <h4 style={{marginTop: 0, marginBottom: 0}}>{comunicado.titulo}</h4>
                    <span style={{width: "100px", textAlign: "end"}}>{comunicado.fixo &&
                      <BsPinAngleFill size={"20px"}/>}</span>
                  </Paper>
                  {comunicado.texto &&
                    <div style={{padding: "10px", textAlign: "center"}}>
                      {parse(comunicado.texto)}
                    </div>
                  }
                </Paper>
              ))}
            </div>
          }
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <div className={"painel-geral"}>
            <div className={"info-user"}>
              <Avatar
                variant={"rounded"}
                alt={user.nome}
                src={user.avatar}
                sx={{width: "150px", height: "150px"}}
              />
              <div className={"info-user-texto"}>
                <h5>{user.nome}</h5>
                {user.nivel.split(",").map((item) => (
                  <p key={item} style={{textTransform: "capitalize"}}>{item}</p>
                ))}
                <Button size={"small"} variant={"outlined"} onClick={() => {
                  navigate('/perfil')
                }}>
                  Ver perfil
                </Button>
              </div>
            </div>
            {insignias.length > 0 &&
              <>
                <Divider sx={{mt: '15px', mb: '15px'}}/>
                <div className={"badges-box"}>
                  <h6>Insígnias:</h6>
                  <div className={"badges"}>
                    {insignias.map((item) => (
                      <Insignia
                        uuid={item.uuid}
                        nome={item.nome}
                        link={item.link}
                        UserUID={user.uuid}
                        key={item.uuid}
                        descricao={item.descricao}
                        informacao={item.informacao}
                        titulo={item.titulo}
                      />
                    ))}
                  </div>
                </div>
              </>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Inicio;
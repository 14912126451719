import {useContext} from "react";
import {ApplicationContext} from "../../../contexts/ApplicationContext";
import {InsigniaAPI} from "../../insignia/components/ModalInsignia/api";

const useCarregarInsignias = () => {
  const {appInfo, setAppInfo} = useContext(ApplicationContext);
  return async (token: string, user_uuid: string, setInsignias: Function) => {
    try {
      let resposta = await InsigniaAPI.getInsignias(token, user_uuid);
      if (!resposta.error) {
        setInsignias(resposta.data);
      } else {
        if (resposta.error.description.nome) {
          setAppInfo({...appInfo, erro: resposta.error.description.nome});
        } else {
          setAppInfo({...appInfo, erro: resposta.error.description});
        }
      }
    } catch (e) {
      setAppInfo({...appInfo, erro: `${e}`});
    }
  }
}

export default useCarregarInsignias;